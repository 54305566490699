.calltoaction {
  text-align: center;
  background-color: $colour2;
  padding: 5em 0 7em 0;
  color: $colour4;
  h4 {
    width: 50%;
    margin: auto;
  }
  div {
    margin-top: 3em;
    a {
      margin-top: 2em;
      padding: 1em 3em;
      border: 0.1em solid $primary;
      text-decoration: none;
      color: $primary;
      &:hover {
        background-color: $primary;
        color: $offwhite;
      }
    }
  }

}
