.profile {
  background-color: $colour2;
color: $colour4;
  text-align: center;
  // background-color: $offwhite;
  padding: 6em 0 6em 0;
  // color: $colour4;
  .profile-container {
    justify-content: space-around;
    align-items: center;
    .left {
      width: 30%;
      padding-top: 40%;
      background-image: url('assets/profile.jpg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      border-radius: 15px;
    }
    .right {
      width: 40%;
      margin-left: 2em;
      text-align: center;
      font-size: 1.414em;
    }
  }
}
