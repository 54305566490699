.logo-nav-container {
  padding: 0 0 1em 0;
  display: flex;
  align-items: center;
  .logo {
    width: 35%;
  }
  .normal-menu {
    width: 65%;
  }
}
