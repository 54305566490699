.quotation {
  background-color: $colour3;
}

.quotation-container {
  color: $offwhite;
  padding: 2em 0;
  justify-content: space-between;
  -webkit-align-content: center;
  align-content: center;
  div {
    padding-right: 1em;
  }
  h3, p {
    margin: 0;
  }
  a {
    text-align: center;
    padding: 1em 3em;
    border: 0.1em solid $offwhite;
    text-decoration: none;
    color: $offwhite;
    &:hover {
      background-color: $offwhite;
      color: $primary;
    }
  }
}
