.contact-details {
  list-style: none;
  padding: 1.3em 0 0.8em 0;
  width: $page-width;
  margin: auto;
  font-size: 0.8em;
  justify-content: flex-end !important;
  svg {
    fill: $darkgrey;
    height: 1.3em;
    vertical-align: middle;
  }
  li {
    margin-left: 2em;
    color: $darkgrey;
    &:nth-child(1) {
      text-align: left;
    }
    &:nth-child(2) {
      text-align: center;
    }
    &:nth-child(3) {
      text-align: right;
    }
  }
}
