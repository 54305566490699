$colour1: #FEFCED;
$colour2: #F2DCC2;
$colour3: #A6866A;
$colour4: #73503C;

$offwhite: $colour1;
$primary: $colour4;
$darkgrey: $colour4;
$logo: $colour4;
$text: $colour4;

$darktext: #333;

$page-width: 85%;

@import "normalize";
@import "typography";
@import "header";
@import "contact-details";
@import "logo";
@import "navigation";
@import "misc";
@import "banner";
@import "quotation";
@import "profile";
@import "calltoaction";
@import "companies";
@import "accreditation";
@import "testimonial";
@import "footer";
@import "responsive";
