.banner {
  background-image: url('assets/example.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 500px;
  h2 {
    display: table;
    padding-top: 2em;
    color: $offwhite;
    white-space: pre-wrap;
    span {
      background: rgba(0, 0, 0, .5);
      vertical-align: middle;
    }
  }
}
