#current {
  border-bottom: 0.2em solid $primary;
}

.normal-menu {
  ul {
    margin-top: 1.8em;
    padding: 0;
    text-align: right;
    list-style: none;
    li {
      display: inline-block;
      margin: 0 0 0 1em;
      border-bottom: 0.2em solid $offwhite;
      &:hover {
        border-bottom: 0.2em solid $primary;
      }
      a {
        text-decoration: none;
        color: $darkgrey;
        font-size: 1.2em;
      }
    }
  }
}

.responsive-menu-toggle {
  display: none;
}

.responsive-menu {
  display: none;
}
