.companies-section {
  padding-top: 10em;
  padding-bottom: 10em;
}

.company-icon {
  height: 5em;
  fill: #282c34;
}

.companies-container {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: space-around;
}

#element1, #element2, #element3 {
  text-align: center;
  flex-direction: column;
  align-self: center;
  h4 {
    margin-top: 0;
    margin-bottom: 2em;
  }
  a {
    padding: 1em 3em;
    border: 0.1em solid $primary;
    text-decoration: none;
    color: $primary;
    &:hover {
      background-color: $primary;
      color: $offwhite;
    }
  }
}

#element1, #element2 {
  padding-right: 3em;
}
