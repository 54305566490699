// Border box fix
html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*, *:before, *:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

// Stop the scroll bar shifting everything over
html {
    overflow-y: scroll;
    background-color: $offwhite;
}

.flex {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.flex-container {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  -webkit-flex-flow: row wrap;
  justify-content: space-between;
  -webkit-align-content: center;
  align-content: center;
}

.page-width {
  width: $page-width;
  margin: auto;
}

.text-center {
  text-align: center;
}

.fadeInBlock {
    opacity:0;
}

.header-area {
  padding-bottom: 0.7em;
}

section, .header-area {
  display: block;
  background-color: $offwhite;
}

.construction-pics {
  img {
    margin: 0 auto 3em auto;
  }
}

a {
  color: $primary;
  text-decoration: none;
}
