footer {
  // Flex settings
  align-items: baseline;

  background-color: $colour4;
  color: $offwhite;
  vertical-align: bottom;
  padding: 1.4em 0 1em 0;
  a {
    text-decoration: none;
    color: $offwhite;
  }
  h4 {
    margin: 0;
  }
}

.footer-contact {
  margin: 0;
  padding: 0;
  li {
    list-style: none;
    svg {
      fill: $offwhite;
      height: 1em;
      vertical-align: middle;
    }
  }
}
