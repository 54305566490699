@media screen and (max-width: 960px) {
  /* Header*/
  .header-area {
    padding-bottom: 0;
  }

  .logo-nav-container {
    width: 85%;
    .logo {
      width: 100%;
    }
  }

  /* Contact detils*/
  .contact-details {
    display: none;
  }

  /* Logo*/
  .logo {
    width: 80%;
    margin-top: 0.6em;
  }

  /* Navigation*/
  .normal-menu {
    display: none;
  }

  .responsive-menu-toggle {
    display: block;
    width: 100%;
    background-color: $darkgrey;
    padding: 0.5em 0;
    text-align: center;
    font-size: 1em;
    margin-top: 0.7em;
    span {
      color: $offwhite;
      vertical-align: middle;
    }
    svg {
      height: 1em;
      stroke: $offwhite;
      vertical-align: middle;
    }
  }

  .responsive-menu {
    ul {
      margin: 0;
      padding: 0;
      background-color: $colour3;
      list-style: none;
      border-bottom: 0.2em solid $darkgrey;
      li {
        padding: 1em 0;
        text-align: center;
        &:hover {
          background-color: $primary;
        }
        a {
          color: $offwhite;
          text-decoration: none;
        }
      }
    }
  }

  #current {
    border: none;
  }

  /* Get a quote section*/
  .quotation-container {

      -ms-flex-flow: row wrap;

          flex-flow: row wrap;
      -webkit-flex-flow: row wrap;
    text-align: center;
    a {
      margin: 2em auto 0 auto;
    }
  }

  /* Companies description*/
  .companies-container {
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
  }

  #element1, #element2 {
    margin-bottom: 3em;
    padding-right: 0;
  }

  /* Projects section*/
  .projects-container {
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
  }

  .project-item {
    width: 500px;
  }

  #project1, #project2, #project3 {
    margin: 0 auto 3em auto;
  }

  #project3 {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 600px) {
  h1 {
    font-size: 2.827em;
  }
  .statistics {
    figure {
      div {
        flex-direction: column;
      }
    }
  }
  .construction-pics {
    display: block;
    img {
      width: 100%;
    }
  }
  .banner {
    h2 {
      padding-bottom: 2em;
    }
  }
  .profile {
    .profile-container {
      flex-wrap: wrap;
      .left {
        width: 100%;
        padding-top: 100%;
      }
      .right {
        width: 100%;
        margin-left: 0;
      }
    }

  }
}
